import { arvinReportGAProfile } from '~/apis/user';

interface CredentialResponse {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
}

declare const window: any;

export function getGAParams () {
  const config = useNuxtApp().$arvinConfig as any;
  if (process.server) { return {}; }
  let gaClientId = ''; let gaLoginPath = ''; let gaSessionId = '';
  try {
    const cookies = document.cookie.split(';').reduce((p, c) => {
      const pair = c.split('=');
      p[pair[0].trim()] = decodeURIComponent(pair[1].trim());
      return p;
    }, {} as any);
    const clientId = cookies._ga; const flag = config.googleMeasurementId?.split('-')[1];
    gaSessionId = cookies['_ga_' + (config.googleMeasurementId ? flag : 'FC7X0EK4ZK')];
    const route = useRoute();
    const next = route.query.next as string;
    gaLoginPath = next ? location.origin + next : location.href;
    if (clientId) {
      let clientIdParts = clientId.split('.');
      gaClientId = clientIdParts[2] + '.' + clientIdParts[3];
    }
  } catch (error) {
    reportEvent('User_Error_Reported', {
      Error_type: `gaClientId error: ${JSON.stringify(document.cookie)} --- ${localStorage.getItem('accept_cookies')}`
    });
  }
  if (!gaClientId) {
    reportEvent('User_Error_Reported', {
      Error_type: `gaClientId error: ${JSON.stringify(document.cookie)} --- ${localStorage.getItem('accept_cookies')}`
    });
    const acceptCookiesKey = 'accept_cookies';
    const acceptCookies = JSON.parse(localStorage.getItem(acceptCookiesKey) || 'null');
    if (acceptCookies?.gaClientId) {
      gaClientId = acceptCookies.gaClientId;
      gaSessionId = acceptCookies.gaSessionId;
    }
  }
  return { gaLoginPath, gaClientId, gaSessionId };
}

export async function postGAProfile () {
  // fetch gaEvent api data
  try {
    const { gaClientId, gaSessionId } = getGAParams();
    await arvinReportGAProfile({
      client_id: gaClientId || '',
      session_id: gaSessionId
    });
  } catch (error) {
    console.error('fetch gaEvent api data error: /ga4/profile/upsert', error);
    reportEvent('User_Error_Reported', {
      Error_type: `fetch gaEvent api data error: ${JSON.stringify(error)} --- ${JSON.stringify(document.cookie)} --- ${localStorage.getItem('accept_cookies')}`
    });
  }
}
